module.exports = {
  amountOptions: [
    {
      value: "500",
      label: "$100 - $500"
    },
    {
      value: "1000",
      label: "$500 - $1,000"
    },
    {
      value: "2500",
      label: "$1,000 - $2,500"
    },
    {
      value: "5000",
      label: "$2,500 - $5,000"
    }
  ],
  loanReasonOptions: [
    {
      label: "Auto Purchase",
      value: "AUTO_PURCHASE"
    },
    {
      label: "Auto Repair",
      value: "AUTO_REPAIR"
    },
    {
      label: "Home Improvement",
      value: "HOME_IMPROVEMENT"
    },
    {
      label: "Debt Consolidation",
      value: "DEBT_CONSOLIDATION"
    },
    {
      label: "Debt Settlement",
      value: "DEBT_SETTLEMENT"
    },
    {
      label: "Credit Card Consolidation",
      value: "CREDIT_CARD_CONSOLIDATION"
    },
    {
      label: "Small Business",
      value: "BUSINESS"
    },
    {
      label: "Rent or Mortgage",
      value: "RENT_OR_MORTGAGE"
    },
    {
      label: "Medical Expenses",
      value: "MEDICAL"
    },
    {
      label: "Moving",
      value: "MOVING"
    },
    {
      label: "Emergency Situation",
      value: "EMERGENCY_SITUATION"
    },
    {
      label: "Education",
      value: "EDUCATION"
    },
    {
      label: "Renewable Energy",
      value: "RENEWABLE_ENGERGY"
    },
    {
      label: "Student Loan Refinance",
      value: "STUDENT_LOAN_REFINANCE"
    },
    {
      label: "Taxes",
      value: "TAXES"
    },
    {
      label: "Other",
      value: "OTHER"
    }
  ],
  emailDomainsOptions: [
    "gmail.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "yahoo.com",
    "icloud.com",
    "yandex.com"
  ],
  currentResidenceDurationOptions: [
    "10+ Years",
    "9 Years",
    "8 Years",
    "7 Years",
    "6 Years",
    "5 Years",
    "4 Years",
    "3 Years",
    "2 Years",
    "1 Year"
  ],
  OwHomeOptions: [
    { label: "Own", value: "YES" },
    { label: "Rent", value: "NO" }
  ],
  incomeSourceOptions: [
    {
      value: "EMPLOYMENT",
      label: "Job income"
    },
    {
      value: "SELF_EMPLOYMENT",
      label: "Self employment"
    },
    {
      value: "MILITARY",
      label: "Military"
    },
    {
      value: "BENEFITS",
      label: "Benefits"
    }
  ],
  payFrequencyOptions: [
    { label: "Every 2 weeks", value: "BIWEEKLY" },
    { label: "Twice a month", value: "TWICEMONTHLY" },
    { label: "Monthly", value: "MONTHLY" },
    { label: "Weekly", value: "WEEKLY" }
  ],
  getDirectDepositOptions: [
    {
      value: "YES",
      label: "Electronic deposit"
    },
    {
      value: "NO",
      label: "Paper check"
    }
  ],
  bankAccountTypeOptions: [
    {
      value: "SAVING",
      label: "Saving"
    },
    {
      value: "CHECKING",
      label: "Checking"
    }
  ],
  currentBankDurationOptions: [
    { label: "1 - 3 month", value: 3 },
    { label: "3 - 6 months", value: 6 },
    { label: "7 - 12 months", value: 12 },
    { label: "1 - 2 year", value: 24 },
    { label: "More than 2 years", value: 30 }
  ],
  creditScoreRatingOptions: [
    {
      value: "EXCELLENT",
      label: "Excellent (720+)"
    },
    {
      value: "GOOD",
      label: "Good (680-719)"
    },
    {
      value: "FAIR",
      label: "Fair (640-679)"
    },
    {
      value: "POOR",
      label: "Poor (639 or less)"
    }
  ],
  currentCompanyDurationOptions: [
    {
      label: "5 years or more",
      value: "60"
    },
    {
      label: "3 - 4 years",
      value: "48"
    },
    {
      label: "1 - 2 years",
      value: "24"
    },
    {
      label: "3 - 12 months",
      value: "12"
    },
    {
      label: "3 months or less",
      value: "3"
    }
  ],
  GrossIncomeOptions: [
    {
      label: "More than $5,500",
      value: "10000"
    },
    {
      label: "$4,501 - $5,500",
      value: "5500"
    },
    {
      label: "$3,501 - $4,500",
      value: "4500"
    },
    {
      label: "$2,501 - $3,500",
      value: "3500"
    },
    {
      label: "$1,501 - $2,500",
      value: "2500"
    },
    {
      label: "Less than $1,500",
      value: "1500"
    }
  ],
  issuingStateOptions: [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" }
  ]
};
