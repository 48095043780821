import sectigo from 'assets/images/security/sectigo-seal.png';
import ssl from 'assets/images/security/aes-256-ssl.png';
import oal from 'assets/images/security/OnlineLenderAlliance.png';
import './SecuritySeal.scss';

export const SecuritySeal = () => {
  return (
    <>
      <div className='security-seal'>
        <img className='sectigo' src={sectigo} alt="security by Sectigo" />
        <img className='ssl' src={ssl} alt="SSL secured" />
        <a href='https://onlinelendersalliance.org/consumer-resources-old/look-for-the-ola-seal/' target='_blank' rel="noreferrer">
          <img className='ola' src={oal} alt="Online Lender Alliance" />
        </a>
      </div>
    </>
  )
}