import excellent from 'assets/images/security/excellent-service.png';
import reliable from 'assets/images/security/reliable-partner.png';
import encryption from 'assets/images/security/256-bit-encrypting.png';
import './BadgeIcons.scss';

export const BadgeIcons = () => {
  return (
    <>
      <div className="badge-icons">
        <div className='wrapper'>
          <img src={excellent} alt="excellent service" />
          <img src={reliable} alt="reliable partner" />
          <img src={encryption} alt="256 bit encrypting" />
        </div>
      </div>
    </>
  );
};
