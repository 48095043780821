import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import "./Partners.scss";
import { getPartners } from "redux/apiCalls";

const Partners = () => {
  const dispatch = useDispatch();
  const partnersRedux = useSelector((state) => state?.partners);
  let [partners, setPartners] = useState(partnersRedux?.value)
  if (!partners) {
    const params = { config: window.location.hostname }
    getPartners(dispatch, params).then(partners=>{setPartners(partners.value)})
  }
  
  return (
    <div className="credit-authorization-agreement">
      <Navbar />
      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Ours partners</h2>
          <p className="partner-list">
            { partners }.
          </p>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default Partners;
