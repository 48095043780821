import { createSlice } from "@reduxjs/toolkit";

const partnersSlice = createSlice({
	name: "partners",
	initialState: {
		partners: null,
		isFetching: false,
		error: false,
	},
	reducers: {
		startFetchingPartners: (state) => {
			state.isFetching = true;
		},
		fetchPartnersSuccess: (state, actions) => {
			state.isFetching = false;
			state.partners = actions.payload;
		},
		fetchPartnersFailure: (state) => {
			state.isFetching = false;
			state.error = true;
		},
	},
});

export const { startFetchingPartners, fetchPartnersSuccess, fetchPartnersFailure } = partnersSlice.actions;
export default partnersSlice.reducer;
