import React from "react";

import img1 from "../../assets/images/howItWork/img1.jpg";
import img2 from "../../assets/images/howItWork/img2.jpg";
import img3 from "../../assets/images/howItWork/img3.jpg";
import nextArrow from "../../assets/images/howItWork/next-arrow.png";
import prevArrow from "../../assets/images/howItWork/prev-arrow.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { Box, Typography } from "@mui/material";

const isMobile = window.innerWidth < 768;

const titleStyle = {
  marginBottom: isMobile ? "25px" : "39px",
  position: "relative",

  color: "#000",
  fontFamily: "Poppins",
  fontSize: isMobile ? "20px" : "25px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textTransform: "uppercase",

  span: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "50px",
    height: "4px",
    backgroundColor: "#06D6A0"
  }
};

const SimpleSlider = () => {
  const slider = React.useRef(null);
  const settings = {
    customPaging: function (i) {
      return (
        <>
          <span>{i + 1}</span>
          <button></button>
        </>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 1000,
    // autoplay: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    spaceBetween: 50
  };

  const options = [
    {
      image: img1,
      title: "1. Submit your information",
      desc: "Complete our simple, secure and fast online form in as quick as a few minutes."
    },
    {
      image: img2,
      title: "2. Fast loan decision",
      desc: "Lenders will review your application and respond quickly if it is approved."
    },
    {
      image: img3,
      title: "3. Check your account",
      desc: "Once your request is approved by lenders, the funds will be directly transferred into your account in as soon as the lenders proceed with your request."
    }
  ];
  return (
    <Box
      sx={{
        ".slick-dots": {
          bottom: 0
        },
        ".slick-dots ": {
          display: "flex !important",
          width: "255px",
          left: "50%",
          transform: "translateX(-50%)",

          "li ": {
            flex: 1,
            margin: "0",
            height: "3px",
            listStyleType: "none",

            span: {
              color: "#606060",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal"
            },
            button: {
              width: "100%",
              height: "3px",
              padding: "0",
              background: "#B7B7B7",

              ":before": {
                display: "none"
              }
            }
          },
          ".slick-active": {
            button: {
              background: "#06D6A0"
            }
          }
        },
        ".slick-prev": {
          top: "35%",
          left: "20px",
          zIndex: 1,
          ":before": {
            content: '""',
            position: "absolute",
            left: 0,
            top: 0,
            width: "11.358px",
            height: "20px",
            background: `url(${prevArrow}) no-repeat center`,
            backgroundSize: "100%"
          }
        },
        ".slick-next": {
          top: "35%",
          right: "20px",
          ":before": {
            content: '""',
            position: "absolute",
            right: 0,
            top: 0,
            width: "11.358px",
            height: "20px",
            background: `url(${nextArrow}) no-repeat center`,
            backgroundSize: "100%"
          }
        }
      }}
    >
      <Slider ref={slider} {...settings}>
        {options.map((item, index) => (
          <Box
            key={index}
            sx={{
              marginBottom: isMobile ? "0" : "20px",
              padding: isMobile ? "0" : "0 10px",
              borderRadius: "10px"
            }}
          >
            <Box>
              <Box
                className="card-image"
                sx={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <img style={{ width: "100%" }} src={item.image} alt="" />
              </Box>
            </Box>

            <Box
              sx={{
                padding: "13px 15px 24px",
                backgroundColor: "#fff",
                borderRadius: "0 0 10px 10px",
                boxShadow:
                  "rgb(0 0 0 / 0%) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                minHeight: isMobile ? "100px" : "163px"
              }}
            >
              <Typography
                variant="h4"
                component="h4"
                sx={{
                  color: "#278BA2",
                  textAlign: "center",
                  fontFamily: "Open Sans",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  marginBottom: "18px"
                }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  color: "#000",
                  textAlign: "center",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  display: "block"
                }}
              >
                {item.desc}
              </Typography>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};
const HowItWork = () => {
  return (
    <Box
      className="how-it-work"
      id="howItWork"
      sx={{
        backgroundColor: "#F5F5F5"
      }}
    >
      <Box
        sx={{
          padding: isMobile ? "24px 28px 33px" : "",
          maxWidth: "1100px",
          margin: isMobile ? "0 auto" : "0 auto"
        }}
      >
        <Box
          sx={{
            padding: isMobile ? "0" : "35px 42px 38px",
            // boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.10)",
            borderRadius: "8px"
          }}
        >
          <Box>
            <Typography
              variant="h3"
              component="h3"
              sx={titleStyle}
              // data-aos="fade-left"
            >
              How it works
              <span></span>
            </Typography>
            <SimpleSlider />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HowItWork;
