import { 
  inHousePersonalLoanRequest,
  partnersRequest
} from "../requestMethods";
import {
  addContactFailure,
  addContactStart,
  addContactSuccess,
  updateContactFailure,
  updateContactStart,
  updateContactSuccess,
  resetContact
} from "./contactRedux";
import { 
  startFetchingPartners,
  fetchPartnersSuccess,
  fetchPartnersFailure
} from "./partnersRedux";

import { convertContactFromResponse } from "../utils/functions";
// ADD CONTACT
export const addContact = async (dispatch, contact) => {
  dispatch(addContactStart());

  try {
    const res = await inHousePersonalLoanRequest.post("/v2", contact);
    if (res.status === 200) {
      const contactFromDB = res?.data?.data?.contact;
      const convertedData = convertContactFromResponse(contactFromDB);
      dispatch(resetContact({contact}))
      dispatch(addContactSuccess({...convertedData, lead_id: contactFromDB.lead_id}));
      sessionStorage.setItem("lead_id", contactFromDB.lead_id);
    } else {
      dispatch(addContactFailure());
    }
    return res;
  } catch (err) {
    dispatch(addContactFailure());
  }
};

// UPADTE CONTACT
export const updateContact = async (dispatch, contactId, contact) => {
  dispatch(updateContactStart());

  try {
    const res = await inHousePersonalLoanRequest.put("/v2", contact);
    if (res.status === 200) {
      dispatch(updateContactSuccess(contact));
    } else {
      dispatch(updateContactFailure());
    }
    return res;
  } catch (err) {
    dispatch(updateContactFailure());
  }
};

// GET PARTNERS
export const getPartners = async (dispatch, params) => {
  dispatch(startFetchingPartners());

  try {
    const res = await partnersRequest.get("", {params: params});
    if (res.status === 200) {
      const partners = res.data?.data
      dispatch(fetchPartnersSuccess(partners));
      return partners
    } else {
      dispatch(fetchPartnersFailure());
      return null;
    }
  } catch (err) {
    dispatch(fetchPartnersFailure());
  }
};
