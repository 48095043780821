import { createSlice } from "@reduxjs/toolkit";

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    temporary: null,
    contact: null,
    isFetching: false,
    error: false
  },
  reducers: {
    addContactStart: (state) => {
      state.isFetching = true;
    },
    addContactSuccess: (state, actions) => {
      state.isFetching = false;
      state.contact = {
        ...state.contact, // Sao chép dữ liệu liên hệ hiện tại
        ...actions.payload // Cập nhật thông tin mới từ actions.payload
      };
      state.error = false;
    },
    addContactFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // UPDATE
    updateContactStart: (state) => {
      state.isFetching = true;
    },
    // updateContactSuccess: (state, actions) => {
    //   state.isFetching = false;
    //   state.contact = actions.payload;
    // },
    updateContactSuccess: (state, actions) => {
      state.isFetching = false;
      state.contact = {
        ...state.contact, // Sao chép dữ liệu liên hệ hiện tại
        ...actions.payload // Cập nhật thông tin mới từ actions.payload
      };
    },
    updateContactFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // DELETE
    deleteContactStart: (state) => {
      state.isFetching = true;
    },
    deleteContactSuccess: (state) => {
      state.isFetching = false;
      state.contact = {
        email: state.contact.email,
        // Các trường khác bạn muốn giữ lại hoặc thiết lập thành giá trị mặc định
        // Ví dụ: name: null, phone: null
      };
    },
    deleteContactFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    resetContact: (state) => {
      state.contact = {}
    },
    addTemporaryContact: (state, actions) => {
      state.temporary = {...actions.payload}
    },
    updateTemporaryContact: (state, actions) => {
      state.temporary = {...state.temporary,...actions.payload}
    }
  }
});

export const {
  addContactStart,
  addContactSuccess,
  addContactFailure,
  updateContactStart,
  updateContactSuccess,
  updateContactFailure,
  deleteContactStart,
  deleteContactSuccess,
  deleteContactFailure,
  resetContact,
  addTemporaryContact,
  updateTemporaryContact
} = contactSlice.actions;
export default contactSlice.reducer;
