const isMobile = window.innerWidth < 768;

const commonStyles = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  lineHeight: "normal"
};

export const textInputStyles = {
  color: "#000",
  fontFamily: "Poppins",
  fontSize: isMobile ? "14px" : "15px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: isMobile && "1.9"
};

export const btnSubmitCustom = {
  flex: 1,
  marginBottom: isMobile ? "0" : "20",
  width: "100%",
  height: isMobile ? "45px" : "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  backgroundColor: "#06D6A0",
  textAlign: "center",
  color: "#FFF",
  fontSize: isMobile ? "15px" : "16px",
  fontWeight: 700,
  textTransform: "uppercase",
  boxShadow: "unset",
  ...commonStyles
};

export const labelCustom = {
  margin: "0 0 3px",
  color: "#000",
  fontFamily: "Poppins",
  fontSize: isMobile ? "12px" : "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal"
};

export const textFieldCustom = {
  // SET HEIGHT FIELD
  "& .MuiInputBase-root": {
    minHeight: isMobile && "45px"
  },

  // marginBottom: "13px",
  "&.show": {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px 8px 0 0"
    }
  },
  "& .MuiOutlinedInput-root": {
    ...textInputStyles,
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#B7B7B7"
    },
    "&:hover fieldset": {
      borderColor: "#B7B7B7"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B7B7B7",
      borderWidth: "1px"
    }
  }
};
export const ListBoxCustom = {
  maxHeight: 228,
  position: "relative",
  padding: 0,
  // borderRadius: "0 0 8px 8px",
  overflow: isMobile ? "unset" : "auto",

  backgroundColor: "#191C1A",

  "& .MuiPaper-root": {
    borderRadius: "0 0 8px 8px"
  },
  "& .MuiAutocomplete-option": {
    minHeight: "38px",
    justifyContent: "center",
    borderBottom: "1px solid #ffffff14",
    // borderRadius: "0 0 8px 8px",

    backgroundColor: "#191C1A",

    color: "#fff",
    fontFamily: "Poppins",
    fontSize: isMobile ? "14px" : "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  },
  "& .Mui-focused": {
    backgroundColor: "#06D6A0 !important"
  },
  // SCROLL BAR
  "&::-webkit-scrollbar": {
    width: "0.4em",
    backgroundColor: "#B7B7B7"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#FFF",
    outline: "0.5px solid #000",
    borderRadius: "8px"
  }
};
