export const checkLastStep = (newContactRedux) => {
  const steps = [
    // { variable: "loanReason", step: "loanreason" },
    // { variable: "firstName", step: "lgname" },
    { variable: "phone", step: "cellphone" },
    // { variable: "bestTimeContact", step: "besttimecontact" },
    { variable: "dob", step: "dob" },
    { variable: "zipCode", step: "address" },
    { variable: "streetAddress", step: "address" },
    { variable: "currentResidenceDuration", step: "hometime" },
    { variable: "residenceOwnership", step: "ownhome" },
    { variable: "incomeSource", step: "incomesource" },
    { variable: "currentCompanyDuration", step: "emptime" },
    { variable: "unsecuredDebtAmount", step: "unsecureddebtamount" },
    { variable: "incomePaymentFrequency", step: "payfreequency" },
    { variable: "monthlyIncome", step: "grossincome" },
    { variable: "incomeNextDate1", step: "nextpaydate" },
    { variable: "employerName", step: "empinfor" },
    { variable: "employerPhone", step: "workphone" },
    { variable: "getDirectDeposit", step: "deposit" },
    { variable: "routingNumber", step: "aba" },
    { variable: "bankAccountType", step: "banktype" },
    { variable: "currentBankDuration", step: "banktime" },
    { variable: "bankAccount", step: "bankaccount" },
    { variable: "licenseID", step: "driverid" },
    { variable: "ownCar", step: "owncar" },
    { variable: "creditScoreRating", step: "creditscore" },
  ];
  let lastStep = null;

  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];
    if (!newContactRedux.hasOwnProperty(step.variable) ||
      (
        newContactRedux.hasOwnProperty(step.variable) &&
        newContactRedux[step.variable] == null
      ))
    {
      lastStep = step;
      break;
    }
  }
  return lastStep?.step ?? null;
};
