import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./NextPayDate.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import { StepPreFooter } from "../../../components/Footer/StepPreFooter";

import NextPayDateIcon from "../../../assets/images/step-icon/NextPayDate-icon.png";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import dayjs from "dayjs";

import { Box } from "@mui/material";

// CUSTOM STYLE
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { nextStepDelayTime } from "../../../utils/const";
import { getPrevStepLink } from "utils/functions";

const NextPayDate = () => {
  const navigate = useNavigate();
  const dispath = useDispatch();

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  const [loadingButton, setLoadingButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [incomeNextDate1, setNextPayDate] = useState(
    contactRedux?.incomeNextDate1 ?? null
  );
  const [incomeNextDate2, setNextPayDate2] = useState(null);
  const incomePaymentFrequency = contactRedux?.incomePaymentFrequency ?? "";

  const today = new Date();
  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 30);

  const tileDisabled = ({ date, view }) => {
    // Loại bỏ các ngày thứ 7 và chủ nhật
    if (view === "month") {
      const day = date.getDay();
      return day === 0 || day === 6;
    }
    return false;
  };
  ///////////////////////////////////

  const tomorrow = dayjs().add(0, "day");
  // HANDLE NEXT PAY DATE 2
  useEffect(() => {
    incomeNextDate1 && clearErrors("incomeNextDate1");
    
    if (incomePaymentFrequency && incomeNextDate1) {
      let incomeNextDate2;

      if (incomePaymentFrequency === "BIWEEKLY") {
        incomeNextDate2 = dayjs(incomeNextDate1).add(14, "day");
      } else if (incomePaymentFrequency === "TWICEMONTHLY") {
        incomeNextDate2 = dayjs(incomeNextDate1).add(14, "day");
      } else if (incomePaymentFrequency === "MONTHLY") {
        incomeNextDate2 = dayjs(incomeNextDate1).add(1, "month");
      } else if (incomePaymentFrequency === "WEEKLY") {
        incomeNextDate2 = dayjs(incomeNextDate1).add(7, "day");
      }

      // Kiểm tra nếu incomeNextDate2 rơi vào thứ 7 hoặc chủ nhật
      if (incomeNextDate2.day() === 6) {
        // Thứ 7
        incomeNextDate2 = incomeNextDate2.add(2, "day"); // Chuyển sang thứ 2
      } else if (incomeNextDate2.day() === 0) {
        // Chủ nhật
        incomeNextDate2 = incomeNextDate2.add(1, "day"); // Chuyển sang thứ 2
      };
      
      setNextPayDate2(incomeNextDate2.format("YYYY-MM-DD"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeNextDate1]);

  // SUBMIT
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    if (!incomeNextDate1) {
      setError("incomeNextDate1", {
        type: "error",
        message: "Please choose an answer"
      });
      return;
    }

    if (dayjs(incomeNextDate1).isBefore(dayjs(tomorrow))) {
      setError("incomeNextDate1", {
        type: "error",
        message: "Please select a date after 30 days from now!"
      });
      return;
    }

    if (loadingButton) {return}
    setLoadingButton(!loadingButton);
    // Format date
    data.incomeNextDate1 = dayjs(incomeNextDate1).format("YYYY-MM-DD");
    data.incomeNextDate2 = dayjs(incomeNextDate2).format("YYYY-MM-DD");

    try {
      const lead_id = sessionStorage.getItem("lead_id");
      const res = await updateContact(dispath, contactRedux?._id, {
        step: 14,
        lead_id,
        incomeNextDate1: data.incomeNextDate1,
        incomeNextDate2: data.incomeNextDate2
      });

      if (res?.status === 200) {
        setTimeout(() => {
          setLoadingButton(false);
          // const lastStep = checkLastStep(contactRedux);
          // lastStep === "nextpaydate" || lastStep === "grossincome"
          //   ? navigate("/empinfor")
          //   : navigate(`/${lastStep}`);
          navigate("/empinfor");
        }, nextStepDelayTime);
      }
    } catch (error) {
      // Handle error
      console.error("Error occurred:", error);
    }
  };

  return (
    <Box className="NextPayDate-page">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={13} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={NextPayDateIcon}
                alt=""
              />
            </Box>
            <h2 className="title">Next Pay Date</h2>
            <h5>Tell us when you will be getting paid next.</h5>
            <div className="form-control">
              {/* incomeNextDate1 */}
              <Calendar
                className="react-calendar"
                onChange={(newDate) => setNextPayDate(newDate)}
                value={incomeNextDate1}
                locale="en-US"
                minDate={today} // Set minDate là hôm nay
                maxDate={maxDate} // Set maxDate là hôm nay + 30 ngày
                tileDisabled={tileDisabled}
              />
              {/* incomeNextDate1 */}
              {errors?.incomeNextDate1 && (
                <p
                  className="error"
                  style={{
                    textAlign: "center",
                    marginBottom: "10px"
                  }}
                >
                  {errors?.incomeNextDate1.message}
                </p>
              )}
            </div>

            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink={prevLink}
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
          <StepPreFooter />
        </div>
      </Box>
      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default NextPayDate;
