import { reversedContactKeysMap, contactKeysMap, stepOrder } from "./const";

const { checkLastStep } = require("./checkLastStep");

export const convertDateFormat = (data) => {
  return data.replace(/-/g, "/");
};

export const trimWhiteSpaceObjValue = (obj = {}) => {
  for (let key in obj) {
    if (typeof obj[key] === "string") {
      obj[key] = obj[key].trim();
    }
  }
};

export const generateRandomNumbers = () => {
  let randomNumber1 = Math.floor(Math.random() * 50);
  let randomNumber2 = Math.floor(Math.random() * (100 - randomNumber1));
  let randomNumber3 = 100 - randomNumber1 - randomNumber2;

  return [randomNumber1, randomNumber2, randomNumber3];
};

export const getListIds = (loanReason = "") => {
  let listIds =
    loanReason === "AUTO_REPAIR"
      ? [15, 43]
      : loanReason === "STUDENT_LOAN_REFINANCE"
      ? [15, 42]
      : loanReason === "EDUCATION"
      ? [15, 41]
      : loanReason === "RENEWABLE_ENGERGY"
      ? [15, 40]
      : loanReason === "TAXES"
      ? [15, 39]
      : loanReason === "EMERGENCY_SITUATION"
      ? [15, 11]
      : loanReason === "MOVING"
      ? [15, 10]
      : loanReason === "MEDICAL"
      ? [15, 9]
      : loanReason === "RENT_OR_MORTGAGE"
      ? [15, 8]
      : loanReason === "BUSINESS"
      ? [15, 7]
      : loanReason === "CREDIT_CARD_CONSOLIDATION"
      ? [15, 6]
      : loanReason === "DEBT_SETTLEMENT"
      ? [15, 5]
      : loanReason === "DEBT_CONSOLIDATION"
      ? [15, 4]
      : loanReason === "HOME_IMPROVEMENT"
      ? [15, 3]
      : loanReason === "AUTO_PURCHASE"
      ? [15, 2]
      : [15, 44]; // OTHER;
  return listIds;
};

export const handleLastStep = (contact) => {
  const lastStep = checkLastStep(contact);
  const stepsRequiringPayFrequency = [
    "nextpaydate",
    "empinfor",
    "workphone",
    "deposit",
    "aba",
    "banktype",
    "banktime",
    "bankaccount",
    "driverid",
    "owncar",
    "creditscore",
    "ssn"
  ];
  const nextPath =
    stepsRequiringPayFrequency.includes(lastStep) || !lastStep
      ? // ? "nextpaydatesort"
        "loanreason"
      : lastStep ?? "loanreason";
  return nextPath;
};

export const convertContactFromResponse = (contact) => {
  const data = {};
  for (const key in contact) {
    data[reversedContactKeysMap()[key]] = contact[key];
  }
  return data;
};

export const contactToPostPayload = (contact) => {
  const payload = {};
  for (const key in contact) {
    if (!!contact[key]) {
      payload[contactKeysMap[key]] = contact[key];
    }
  }
  return payload;
}

export const getPrevStepLink = (path) => {
  try {
    const parts = path.split('/')
    const currentPath = parts[parts.length - 1]
    const currentIndex = stepOrder.indexOf(currentPath)
    const prevPath = stepOrder[currentIndex - 1]
    parts[parts.length - 1] = prevPath
    return path.replace(/\/[^/]+\/?$/, `/${prevPath}`);
    // return "/" + parts.join('/')
  } catch (_) {
    return "#"
  }
}

export const getTrustedFormURL = () => {
  window.onload = function () {
    const trustedFormURLEL = document.querySelector(
      'input[name="xxTrustedFormCertUrl"]'
    );
    
    return trustedFormURLEL?.value || "";
  };
};
