export const config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  zipCodeKey: process.env.REACT_APP_ZIPCODE_KEY
}
export const nextStepDelayTime = 50;
export const currentBankDurationOptionsMap = {
  "1 - 3 month": 3,
  "3 - 6 months": 6,
  "7 - 12 months": 12,
  "1 - 2 year": 24,
  "More than 2 years": 30,
}
export const currentCompanyDurationOptionsMap = {
  "5 years or more": 60,
  "3 - 4 years": 48,
  "1 - 2 years": 24,
  "3 - 12 months": 12,
  "3 months or less": 3
}
export const contactKeysMap = {
  bankAccount: 'bank_account_number',
  bankAccountType: 'bank_account_type',
  bankName: 'bank_name',
  bestTimeContact: 'contact_time',
  city: 'city',
  createdAt: 'created_at',
  creditScoreRating: 'credit_score',
  currentBankDuration: 'bank_account_length_month',
  currentCompanyDuration: 'work_time_at_employer',
  currentResidenceDuration: 'time_at_residence',
  dob: 'dob',
  email: 'email',
  employerName: 'work_company_name',
  employerPhone: 'work_phone',
  firstName: 'first_name',
  getDirectDeposit: 'bank_direct_deposit',
  incomeSource: 'income_type',
  ipAddress: 'user_ip',
  issuingState: 'drivers_license_state',
  jobTitle: 'job_title',
  lastName: 'last_name',
  licenseID: 'drivers_license_number',
  loanReason: 'loan_reason',
  amount: 'loan_amount',
  monthlyIncome: 'income_net_monthly',
  incomeNextDate1: 'income_next_date_1',
  incomeNextDate2: 'income_next_date_2',
  ownCar: 'own_car',
  incomePaymentFrequency: 'income_payment_frequency',
  phone: 'home_phone',
  residenceOwnership: 'own_home',
  routingNumber: 'bank_aba',
  socialSecurityNumber: 'ssn',
  state: 'state',
  streetAddress: 'address',
  testMode: 'test_mode',
  unsecuredDebtAmount: 'debt_amount',
  updatedAt: 'updated_at',
  userAgent: 'user_agent',
  zipCode: 'zip'
}
export const reversedContactKeysMap = () => {
  const maps = {}
  for(const key in contactKeysMap) {
    maps[contactKeysMap[key]] = key
  }
  return maps
}
export const phonePattern = /(\((?!0|1)\d{3}\)-\d{3}-\d{4})|((?!0|1)\d{3}\d{3}\d{4})/

export const stepOrder = [
  "lgname",
  "email",
  "cellphone",
  "dob",
  "address",
  "hometime",
  "ownhome",
  "incomesource",
  "emptime",
  "unsecureddebtamount",
  "payfreequency",
  "grossincome",
  "nextpaydate",
  "empinfor",
  "workphone",
  "deposit",
  "aba",
  "banktype",
  "banktime",
  "bankaccount",
  "driverid",
  "owncar",
  "creditscore",
  "ssn"
]
