import { Box } from "@mui/material";
import { SecuritySeal } from "components/SecuritySeal/SecuritySeal";
import { BadgeIcons } from "components/BadgeIcons/BadgeIcons";
import "./StepPreFooter.scss";

export const StepPreFooter = () => {
  return (
    <>
      <Box sx={{
        width: "100%",
        maxWidth: "700px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "20px"
      }}>
        <BadgeIcons />
        <SecuritySeal />
        <div className="copyright">
          Copyright © 2024 HANFINCAL.<br />
          All rights reserved.
        </div>
      </Box>
    </>
  )
}
