import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./ABARoutingNumber.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";

import { StepPreFooter } from "../../../components/Footer/StepPreFooter";
import { useLocation } from "react-router-dom";
import { getPrevStepLink } from "../../../utils/functions";

import ABAIcon from "../../../assets/images/step-icon/aba-icon.png";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm, Controller } from "react-hook-form";
import {
  Box,
  CircularProgress,
  FormHelperText,
  TextField
} from "@mui/material";
import { FormControl } from "@mui/material";
import {
  formControlCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
// CUSTOM STYLE
import axios from "axios";
import { trimWhiteSpaceObjValue } from "../../../utils/functions";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { nextStepDelayTime } from "../../../utils/const";

const ABARoutingNumber = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  // eslint-disable-next-line no-unused-vars
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const [showCircular, setShowCircular] = useState(false);
  // const [showCard, setShowCard] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [routingNumber, setRoutingNumber] = useState(
    contactRedux?.bankAba ?? contactRedux?.routingNumber ?? ""
  );
  const [bankName, setBankName] = useState(contactRedux?.bankName ?? "");

  const checkBankInfo = async (routingNumber) => {
    setShowCircular(!showCircular);
    const options = {
      method: "GET",
      url: `https://routing-number-bank-lookup.p.rapidapi.com/api/v1/${routingNumber}`,
      params: {
        format: "json",
        paymentType: "ach"
      },
      headers: {
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_KEY,
        "X-RapidAPI-Host": "routing-number-bank-lookup.p.rapidapi.com"
      }
    };

    try {
      const response = await axios.request(options);

      setShowCircular(false);

      if (response?.data[0]?.status === "success") {
        const name = response?.data[0]?.data?.name;
        setBankName(name);
        clearErrors("routingNumber");
        return name
      } else {
        setBankName("");
        setError("routingNumber", {
          type: "required",
          message: "Invalid Routing Number!"
        });
        return false
      }
    } catch (error) {
      setBankName("");
      setError("routingNumber", {
        type: "required",
        message: "Invalid Routing Number!"
      });
      console.error(error);
      setShowCircular(false);
      return false
    }
  };

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    if (loadingButton) { return }
    setLoadingButton(true);

    if (bankName === "") {
      const name = await checkBankInfo(routingNumber)
      if (name) {
        clearErrors("routingNumber");
        data.bankName = name;
      } else {
        setError("routingNumber", {
          type: "required",
          message: "Invalid Routing Number!"
        });
        setLoadingButton(false)
        return false;
      }
    }
    // DELETE WHITE SPACE FROM OBJECT VALUE
    trimWhiteSpaceObjValue(data);
    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      step: 18,
      lead_id,
      bankAba: routingNumber,
      bankName: data.bankName ?? bankName
    });

    if (res?.status === 200) {
      setTimeout(() => {
        navigate("/banktype");
        setLoadingButton(false);
      }, nextStepDelayTime);
    }
  };
  return (
    <Box className="ABARoutingNumber-page">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={17} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={ABAIcon}
                alt=""
              />
            </Box>
            <h2 className="title">ABA Routing Number</h2>
            <h5>
              Where would you like the funds to be deposited? The ABA number is
              a 9-digit code that identifies your bank.
            </h5>
            <div className="form-control">
              {/* routingNumber */}
              <Controller
                name="routingNumber"
                control={control}
                defaultValue={routingNumber}
                rules={{
                  required: "Routing Number is required!",
                  validate: (value) => {
                    if (!/^\d{9}$/.test(value)) {
                      return "Routing Number must be a 9-digit number.";
                    }
                    return true;
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom
                    }}
                  >
                    <FormHelperText sx={labelCustom}>
                      ABA Routing Number
                    </FormHelperText>
                    <TextField
                      {...field}
                      inputRef={(input) => {
                        input?.addEventListener("focus", () => {
                          input?.setAttribute("inputmode", "numeric");
                        });
                        ref(input);
                      }}
                      id="routingNumber"
                      // autoComplete="routingNumber"
                      variant="outlined"
                      fullWidth
                      error={!!errors?.routingNumber}
                      label={routingNumber ? "" : "e.g. 026009593"}
                      helperText={
                        errors?.routingNumber && errors?.routingNumber?.message
                      }
                      inputProps={{
                        maxLength: 9,
                        onInput: (e) => {
                          const value = e.target.value.replace(/[^0-9]/g, "");
                          field.onChange(value); // Update the value in the Controller
                          setRoutingNumber(value); // Update the state
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value.length === 9) {
                          checkBankInfo(e.target.value);
                        }
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: { ...textInputStyles, color: "#B7B7B7" }
                      }}
                      value={field.value} // Use field.value to reflect changes
                      size={isMobile ? "small" : "medium"}
                      sx={textFieldCustom}
                      InputProps={{
                        endAdornment: showCircular ? (
                          <CircularProgress
                            size={windowWidth > 768 ? 20 : 15}
                            color="inherit"
                          />
                        ) : null
                      }}
                    />
                  </FormControl>
                )}
              />
              {/* routingNumber */}
              <Box sx={{ margin: "0 0 20px" }}>
                {bankName && (
                  <span
                    style={{
                      color: "#606060",
                      textAlign: "justify",
                      fontFamily: "Open Sans",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal"
                    }}
                  >
                    {bankName}
                  </span>
                )}
              </Box>
            </div>
            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink={prevLink}
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
          <StepPreFooter />
        </div>
      </Box>

      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default ABARoutingNumber;
